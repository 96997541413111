export default function t() {
  const t = {
    allowEmptyProtocol: false,
    allowLocal: false,
    protocol: "http, https, ftp",
  };
  return {
    validate(o) {
      if (o.value === "") {
        return { valid: true };
      }
      const a = Object.assign({}, t, o.options);
      const l = a.allowLocal === true || `${a.allowLocal}` === "true";
      const f =
        a.allowEmptyProtocol === true || `${a.allowEmptyProtocol}` === "true";
      const u = a.protocol.split(",").join("|").replace(/\s/g, "");
      const e = new RegExp(
        "^" +
          "(?:(?:" +
          u +
          ")://)" +
          (f ? "?" : "") +
          "(?:\\S+(?::\\S*)?@)?" +
          "(?:" +
          (l
            ? ""
            : "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
              "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
              "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})") +
          "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
          "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
          "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
          "|" +
          "(?:(?:[a-z\\u00a1-\\uffff0-9]-?)*[a-z\\u00a1-\\uffff0-9]+)" +
          "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-?)*[a-z\\u00a1-\\uffff0-9])*" +
          "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
          (l ? "?" : "") +
          ")" +
          "(?::\\d{2,5})?" +
          "(?:/[^\\s]*)?$",
        "i"
      );
      return { valid: e.test(o.value) };
    },
  };
}
