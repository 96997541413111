import e from "../utils/format";
export default function t() {
  const t = (e) => {
    let t = e.length;
    for (let s = e.length - 1; s >= 0; s--) {
      const n = e.charCodeAt(s);
      if (n > 127 && n <= 2047) {
        t++;
      } else if (n > 2047 && n <= 65535) {
        t += 2;
      }
      if (n >= 56320 && n <= 57343) {
        s--;
      }
    }
    return `${t}`;
  };
  return {
    validate(s) {
      const n = Object.assign(
        {},
        { message: "", trim: false, utf8Bytes: false },
        s.options
      );
      const a =
        n.trim === true || `${n.trim}` === "true" ? s.value.trim() : s.value;
      if (a === "") {
        return { valid: true };
      }
      const r = n.min ? `${n.min}` : "";
      const l = n.max ? `${n.max}` : "";
      const i = n.utf8Bytes ? t(a) : a.length;
      let g = true;
      let m = s.l10n ? n.message || s.l10n.stringLength.default : n.message;
      if ((r && i < parseInt(r, 10)) || (l && i > parseInt(l, 10))) {
        g = false;
      }
      switch (true) {
        case !!r && !!l:
          m = e(s.l10n ? n.message || s.l10n.stringLength.between : n.message, [
            r,
            l,
          ]);
          break;
        case !!r:
          m = e(
            s.l10n ? n.message || s.l10n.stringLength.more : n.message,
            `${parseInt(r, 10) - 1}`
          );
          break;
        case !!l:
          m = e(
            s.l10n ? n.message || s.l10n.stringLength.less : n.message,
            `${parseInt(l, 10) + 1}`
          );
          break;
        default:
          break;
      }
      return { message: m, valid: g };
    },
  };
}
