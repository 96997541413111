import e from "../utils/format";
export default function a() {
  return {
    validate(a) {
      if (a.value === "") {
        return { valid: true };
      }
      const s = Object.assign({}, { inclusive: true, message: "" }, a.options);
      const t = parseFloat(`${s.min}`.replace(",", "."));
      return s.inclusive
        ? {
            message: e(
              a.l10n ? s.message || a.l10n.greaterThan.default : s.message,
              `${t}`
            ),
            valid: parseFloat(a.value) >= t,
          }
        : {
            message: e(
              a.l10n ? s.message || a.l10n.greaterThan.notInclusive : s.message,
              `${t}`
            ),
            valid: parseFloat(a.value) > t,
          };
    },
  };
}
