<!-- 
Copyright 2021
  __  __                       _           _     _   _            _ _   _
 |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
 | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
 | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
 |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|

  All Rights Reserved.

 NOTICE:  All information contained herein is, and remains
 the property of Moonshot Health Incorporated and its suppliers,
 if any.  The intellectual and technical concepts contained
 herein are proprietary to Adobe Systems Incorporated
 and its suppliers and may be covered by U.S. and Foreign Patents,
 patents in process, and are protected by trade secret or copyright law.
 Dissemination of this information or reproduction of this material
 is strictly forbidden unless prior written permission is obtained
 from Moonshot Health Incorporated.
-->
<template>
  <v-app>
    <v-container fluid class="fill-height pt-0 pb-0">
      <v-row class="fill-height d-flex">
        <v-col
          sm="5"
          cols="12"
          class="primary white--text d-flex flex-column pb-0"
        >
          <v-row class="pt-xs-11 pt-sm-16">
            <v-img
              :src="
                require('@/assets/logo/white_logo_transparent_background.png')
              "
              max-height="200px"
              :contain="true"
            />
          </v-row>
          <v-row class="align-end mb-0 hidden-xs-only">
            <v-img :src="require('@/assets/stock/iStock-682257388.jpg')" />
          </v-row>
        </v-col>
        <v-col
          sm="7"
          cols="12"
          class="d-flex flex-column align-center justify-center"
        >
          <v-col
            sm="12"
            md="10"
            cols="12"
            class="d-flex flex-column justify-center"
          >
            <v-form id="loginForm">
              <v-card>
                <v-card-title>
                  <h3 class="text-sm-h4 text-h6">
                    {{ $t("msh.login.welcome") }}
                  </h3>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="form.email"
                    :label="$t('msh.login.email')"
                    name="email"
                    :error-messages="form.error.email"
                  >
                  </v-text-field>
                  <v-text-field
                    value=""
                    :label="$t('msh.login.password')"
                    type="password"
                    name="password"
                    v-model="form.password"
                    :error-messages="form.error.password"
                  >
                  </v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    block
                    type="submit"
                    :loading="loading"
                    :disabled="loading"
                  >
                    {{ $t("msh.login.signIn") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

@media (min-width: #{map-get($grid-breakpoints, 'sm')} )
    .v-application .pt-sm-16
        padding-top: 130px !important
</style>
<script>
import formValidation from "@/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/plugins/formvalidation/dist/es6/plugins/Trigger";
import SubmitButton from "@/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import { AUTH_LOGIN_REQUEST } from "@/store/constants";
import { BUILDING_SET_CURRENTCONFIRMED, AUTH_LOGINERROR } from "@/events";
import { bus } from "@/main.js";

export default {
  name: "login",
  data() {
    return {
      state: "signin",
      loading: false,
      form: {
        email: "",
        password: "",
        error: {
          message: "",
          email: "",
          password: "",
        },
      },
    };
  },
  mounted() {
    // Field Validation
    this.fv = formValidation(document.getElementById("loginForm"), {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: this.$t("msh.validation.required.email"),
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t("msh.validation.required.password"),
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
      },
    })
      .on("core.form.valid", () => {
        this.login(this.form.email, this.form.password);
      })
      .on("core.validator.validated", (event) => {
        if (!event.result.valid) {
          this.form.error[event.field] = event.result.message;
        } else {
          this.form.error[event.field] = "";
        }
      });

    // Listen on BUILDING_SET_CURRENTCONFIRMED
    // This way we remove the loading as we are being redirected in the app
    // eliminate possible flicker issue on slower connection and/or backend delays
    bus.$on(BUILDING_SET_CURRENTCONFIRMED, (user) => {
      this.loading = false;
    });
    // Listen on AUTH_LOGINERROR
    bus.$on(AUTH_LOGINERROR, (error) => {
      this.loading = false;
      if (error.includes("password")) this.form.error.password = error;
      else this.form.error.email = error;
    });
  },
  methods: {
    login: function (email, password) {
      this.$store.dispatch(AUTH_LOGIN_REQUEST, { email, password });
      this.loading = true;
    },
  },
  beforeDestroy() {
    if (this.fv) {
      this.fv.destroy();
    }
  },
};
</script>
